/* eslint-disable */

// 验证用户名
export function dottom(username) {
  const myReg = /^([1-9]+[0-9]*)|((([1-9]+[0-9]*)|[0]).[0-9]{1,2})|[0]$/;
  return myReg.test(username);
}
// 验证用户名
export function userNameCheck(username) {
  const myReg = /^(?=.*[a-zA-Z])\w{4,20}$/i;
  return myReg.test(username);
}
// 验证手机号码
export function mobileCheck(phone) {
  const myReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  return myReg.test(phone);
}

// 验证身份证
export function sfzNumCheck(sfzNum) {
  const myReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X)$)/;
  return myReg.test(sfzNum);
}

// 验证邮箱
export function mailCheck(mail) {
  // const myReg = /^([a-zA-Z\d\w\-\.])+@[\w\-]+(\.[a-zA-Z]{2,4})+$/;
  const myReg = /^([a-zA-Z\d])(\w|\-)+(\.\w+)*@[a-zA-Z\d-]+(\.[a-zA-Z]{2,4})+$/;
  // const myReg = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d-]+(\.[a-zA-Z]{2,4})+$/;
  return myReg.test(mail);
}
// 密码
export function checkStrong(sValue) {
  var modes = 0;
  if (sValue.length < 6) return modes;
  if (/\d/.test(sValue)) modes++;
  if (/[a-z]/.test(sValue)) modes++;
  if (/[A-Z]/.test(sValue)) modes++;
  if (/\W/.test(sValue)) modes++;
  switch (modes) {
    case 1:
      return 1;
      break;
    case 2:
      return 2;
      break;
    case 3:
    case 4:
      return sValue.length < 4 ? 2 : 3;
      break;
  }
  return modes;
}

export function getDate(fmt, dt = new Date()) {
  let o = {
    "M+": dt.getMonth() + 1, //月份
    "d+": dt.getDate(), //日
    "h+": dt.getHours(), //小时
    "m+": dt.getMinutes(), //分
    "s+": dt.getSeconds(), //秒
    "q+": Math.floor((dt.getMonth() + 3) / 3), //季度
    S: dt.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (dt.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (let k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
}

// 获取两个日期相差的好描述
export function timeSpan(d1, d2) {
  let dateBegin = new Date(d1.replace(/-/g, "/")); //将-转化为/，使用new Date
  let dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date
  let dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
  return Math.round(dateDiff / 1000);
}

// 日期格式化
export function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
  console.log(time_str)
	return time_str
}
